import { Hypersheet } from "../index";

export class DimensionManager {
  hypersheet: Hypersheet;
  constructor(hypersheet: Hypersheet) {
    this.hypersheet = hypersheet;
  }
  addColumns(LS_sheetIndex: string, indexCol: number, countColumns: number) {
    const sheetNameRaw = this.hypersheet.LS_getSheetNameByIndex(LS_sheetIndex);

    const managerSheetId = this.hypersheet.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      this.hypersheet.manager.addColumns(managerSheetId, [
        indexCol,
        countColumns,
      ]);
      this.hypersheet.updateDependentFormulas(managerSheetId, LS_sheetIndex);
    }
  }

  addRows(LS_sheetIndex: string, indexRow: number, countRows: number) {
    const sheetNameRaw = this.hypersheet.LS_getSheetNameByIndex(LS_sheetIndex);

    const managerSheetId = this.hypersheet.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      const changedCells = this.hypersheet.manager.addRows(managerSheetId, [
        indexRow,
        countRows,
      ]);
      console.warn(changedCells, "changedCells");
      this.hypersheet.updateDependentFormulas(managerSheetId, LS_sheetIndex);
    }
  }

  removeColumns(LS_sheetIndex: string, indexCol: number, countColumns: number) {
    const sheetNameRaw = this.hypersheet.LS_getSheetNameByIndex(LS_sheetIndex);

    const managerSheetId = this.hypersheet.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      this.hypersheet.manager.removeColumns(managerSheetId, [
        indexCol,
        countColumns,
      ]);
      this.hypersheet.updateDependentFormulas(managerSheetId, LS_sheetIndex);
    }
  }

  removeRows(LS_sheetIndex: string, indexRow: number, countRows: number) {
    const sheetNameRaw = this.hypersheet.LS_getSheetNameByIndex(LS_sheetIndex);

    const managerSheetId = this.hypersheet.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      this.hypersheet.manager.removeRows(managerSheetId, [indexRow, countRows]);
      this.hypersheet.updateDependentFormulas(managerSheetId, LS_sheetIndex);
    }
  }
}
