import { HyperLuckysheet } from "../HyperLuckysheet";
import { Hypersheet } from "../Hypersheet";

type EngineTypes = "hypersheet" | "hyperformula";

class EngineManagerFactory {
  static createEngineManager(typeEngine: EngineTypes) {
    if (typeEngine === "hyperformula") {
      return new HyperLuckysheet();
    } else if (typeEngine === "hypersheet") {
      return new Hypersheet();
    } else {
      console.error("Неизвестный тип ядра вычисления: " + typeEngine);
      // throw new Error("Неизвестный тип ядра вычисления: " + typeEngine);
    }
  }
}

export default EngineManagerFactory;
