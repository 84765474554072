export function convertCellDataToManagerData(
  celldata,
  russianSheets: string[]
) {
  let newValuesArrs: string[][] = [[]];

  celldata?.forEach((cell: any) => {
    const { r: row, c: column, v } = cell || {};
    const { v: originalValue, f: formulaRaw } = v || {};

    let formula = formulaRaw;
    if (formula && hasCrossRef(formula)) {
      formula = wrapRussianSheetsWithQuotes(formula, russianSheets);
    }

    if (!newValuesArrs[row]) {
      newValuesArrs[row] = [];
    }
    newValuesArrs[row][column] = formula ? formula : originalValue || undefined;
  });

  const rowsLength = newValuesArrs.length;

  for (let ii = 0; ii < rowsLength; ii++) {
    if (!newValuesArrs[ii]) {
      newValuesArrs[ii] = new Array(rowsLength).fill(undefined);
    }
  }

  return newValuesArrs;
}

export function containsRussian(name) {
  return /[А-Яа-яЁё]/.test(name);
}

export function removeSingleQuotes(input: string) {
  return input.replace(/'/g, "");
}

export function hasCrossRef(input: string) {
  return input.includes("!");
}

// TODO: Стоит дописать доп. логику по удалению кавычек перед их добавление, чтобы не было дубликатов
export function wrapRussianSheetsWithQuotes(
  inputString: string,
  russianSheets: string[]
) {
  if (russianSheets.length === 0) {
    return inputString;
  }
  // Создаем регулярное выражение для поиска всех слов из массива без учета границ слов
  const regex = new RegExp(`(?<!')(${russianSheets.join("|")})(?!')`, "g");

  // Заменяем найденные вхождения, оборачивая их в одинарные кавычки
  return inputString.replace(regex, "'$1'");
}

export function removeQuotesFromWords(
  inputString: string,
  words: string[]
): string {
  if (words.length === 0) {
    return inputString;
  }
  // Создаем регулярное выражение, чтобы находить слова, обернутые в кавычки
  const regex = new RegExp(`'(${words.join("|")})'`, "g");

  // Заменяем найденные слова без кавычек
  return inputString.replace(regex, "$1");
}

/**
 * Проверяет наличие слова "TRANSPOSE" в строке (независимо от регистра)
 * @param input - Строка, в которой выполняется поиск
 * @returns boolean - true, если строка содержит "TRANSPOSE", иначе false
 */
export function containsTransposeFormula(input: string): boolean {
  return input.toLowerCase().includes("transpose");
}
