import HyperFormula, {
  DetailedCellError,
  ExportedCellChange,
  ExportedChange,
} from "hyperformula";
import server from "../../controllers/server";
import { getSheetIndexByName } from "../../methods/get";
import { datagridgrowth } from "../getdata";
import { Logger } from "../logger/logger";
import { CONFIG } from "./config";
import Store from "../../store";
import { luckysheetrefreshgrid } from "../refresh";
import {
  containsRussian,
  hasCrossRef,
  removeQuotesFromWords,
  wrapRussianSheetsWithQuotes,
} from "../HyperformulaUtils";
import { LSCell, LuckysheetWindow } from "./types";
import { convertCellDataToManagerData } from "../HyperformulaUtils";
import { DimensionManager } from "./controllers/DimensionManager";

declare const window: LuckysheetWindow;

export class Hypersheet {
  static instance: Hypersheet;
  manager: HyperFormula;
  dimensionManager: DimensionManager;
  LS_sheets: any;

  constructor() {
    if (Hypersheet.instance) {
      return Hypersheet.instance;
    }

    Hypersheet.instance = this;
    this.dimensionManager = new DimensionManager(this);
    window.computationEngine = this;
    Logger.info("Используется: Hypersheet", "ENGINE");
  }

  buildAllSheets(sheets: any[]) {
    this.LS_sheets = sheets;
    const russianSheets = sheets
      .filter(sheet => containsRussian(sheet.name))
      .map(sh => sh.name);

    const preparedData = sheets.reduce((acc, currentSheet) => {
      const { name, celldata } = currentSheet;

      return {
        ...acc,
        [name]: convertCellDataToManagerData(celldata, russianSheets),
      };
    }, {});
    const emptyData = datagridgrowth([], 100, 30);
    Store.setFlowData(emptyData);
    luckysheetrefreshgrid();

    this.manager = HyperFormula.buildFromSheets(preparedData, CONFIG);
    const firstSheetCelldata = this.LS_sheets[0].celldata;

    Store.setFlowData(
      this.initilializeFlowdata(firstSheetCelldata, Store.flowdata)
    );

    this.manager.on("valuesUpdated", changedCells => {
      const { cellsCreated, cellsUpdated } =
        this.LS_mutateCelldata(changedCells);
      this.LS_saveCells(cellsCreated, cellsUpdated);
      luckysheetrefreshgrid();
    });
  }

  private initilializeFlowdata(celldata: any[], emptyData: any[][]) {
    const resultArray = emptyData.map(row => [...row]);

    // Заполняем целевой массив на основе данных из sourceArray
    celldata?.forEach(({ r, c, v }) => {
      if (resultArray[r] && resultArray[r][c] !== undefined) {
        resultArray[r][c] = v;
      }
    });

    return resultArray;
  }

  getCellValue(row: number, col: number, sheetLSIndex?: string) {
    const sheetNameRaw = this.LS_getSheetNameByIndex(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      const cellAddress = { col, row, sheet: managerSheetId };
      const val = this.manager.getCellValue(cellAddress);
      return val;
    }
  }

  pasteContent(
    topLeft: { row: number; col: number },
    contentData: string | number[][],
    sheetLSIndex: string
  ) {
    const { row, col } = topLeft;
    const sheetNameRaw = this.LS_getSheetNameByIndex(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      const pasteAddress = { row, col, sheet: managerSheetId };
      this.manager.setCellContents(pasteAddress, contentData);
    }
  }

  updateCell(row: number, col: number, cellLS: LSCell, sheetLSIndex: string) {
    const { v: cellValue, f: formulaRaw } = cellLS || {};
    const russianSheets = this.manager.getSheetNames().filter(containsRussian);

    let formula = formulaRaw;
    if (formula && hasCrossRef(formula)) {
      formula = wrapRussianSheetsWithQuotes(formula, russianSheets);
    }

    const sheetNameRaw = this.LS_getSheetNameByIndex(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      const value = formula || cellValue;
      const cellAddress = { col, row, sheet: managerSheetId };
      const currentFormula = this.manager.getCellFormula(cellAddress);
      const existFormula = this.manager.doesCellHaveFormula(cellAddress);
      const currentValue = this.manager.getCellValue(cellAddress);

      const isSameFormula = existFormula && currentFormula === formula;
      const isSameValue = currentValue && currentValue === cellValue;

      if (isSameValue || isSameFormula) {
        return;
      }

      this.manager.setCellContents(cellAddress, value);

      const hasFormula = this.manager.doesCellHaveFormula(cellAddress);
      const formulaCell = this.manager.getCellFormula(cellAddress);

      Logger.info(
        `[${row}, ${col}]: ${hasFormula ? formulaCell : ""} ${
          cellValue ? cellValue : ""
        }`,
        `UPDATE CELL IN ${sheetNameRaw}`
      );
    }
  }

  clearContent(
    start: { row: number; col: number },
    end: { row: number; col: number },
    sheetLSIndex: string
  ) {
    const sheetNameRaw = this.LS_getSheetNameByIndex(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      const { row: startRow, col: startCol } = start;
      const { row: endRow, col: endCol } = end;
      const rowsLength = endRow - startRow + 1;
      const colsLength = endCol - startCol + 1;

      const clearArea = Array(rowsLength)
        .fill([])
        .map(arr => Array(colsLength).fill(undefined));
      const topLeftAddress = {
        row: startRow,
        col: startCol,
        sheet: managerSheetId,
      };

      const changedCells = this.manager.setCellContents(
        topLeftAddress,
        clearArea
      );
      console.log(changedCells, "changedCells AFTER DELETE");
    }
  }

  createNewSheet(sheetName: string) {
    this.manager.addSheet(`${sheetName}`);
  }

  changeSheet(LS_sheetIndex: string) {
    const emptyData = datagridgrowth([], 100, 30);
    Store.setFlowData(emptyData);
    luckysheetrefreshgrid();

    const nextSheetCelldata = this.LS_sheets.find(
      sheet => sheet.index === LS_sheetIndex
    ).celldata;

    Store.setFlowData(
      this.initilializeFlowdata(nextSheetCelldata, Store.flowdata)
    );
  }

  renameSheet(oldSheetName: string, newSheetName: string) {
    const sheetId = this.manager.getSheetId(oldSheetName);
    const LS_sheetIndex = this.LS_getSheetIndexByName(newSheetName);

    if (sheetId !== undefined) {
      this.manager.renameSheet(sheetId, `${newSheetName}`);
      this.viewRawSheet(sheetId, newSheetName, `RENAME ${oldSheetName} ->`);
      this.updateDependentFormulas(sheetId, LS_sheetIndex);
    }
  }

  addColumns(sheetLSIndex: string, indexCol: number, countColumns: number) {
    this.dimensionManager.addColumns(sheetLSIndex, indexCol, countColumns);
  }

  addRows(sheetLSIndex: string, indexRow: number, countRows: number) {
    this.dimensionManager.addRows(sheetLSIndex, indexRow, countRows);
  }

  removeColumns(sheetLSIndex: string, indexCol: number, countColumns: number) {
    this.dimensionManager.removeColumns(sheetLSIndex, indexCol, countColumns);
  }

  removeRows(sheetLSIndex: string, indexRow: number, countRows: number) {
    this.dimensionManager.removeRows(sheetLSIndex, indexRow, countRows);
  }

  public viewAllRawSheets() {
    const allRawSheets = Hypersheet.instance.manager.getAllSheetsSerialized();
    Object.entries(allRawSheets).forEach(([listName, table]) => {
      Logger.table(table, "HYPERFORMULA RAW VALUES", listName);
    });
  }

  public viewAllSheetsValues() {
    const allValuesSheets = Hypersheet.instance.manager.getAllSheetsValues();
    Object.entries(allValuesSheets).forEach(([listName, table]) => {
      Logger.table(table, "HYPERFORMULA VALUES", listName);
    });
  }

  private viewRawSheet(sheetId: number, sheetNameRaw: string, action: string) {
    Logger.table(
      this.manager.getSheetSerialized(sheetId),
      action,
      sheetNameRaw
    );
  }

  LS_getSheetNameByIndex(sheetIndex?: string) {
    return window.luckysheet.sheetmanage.getSheetName(sheetIndex);
  }

  LS_getSheetIndexByName(sheetName: string) {
    return getSheetIndexByName(sheetName);
  }

  /**
   * Обновляет все формулы, которые зависят от текущего листа
   * Также обновляет состояние LS
   * @param HF_sheetId - айди листа, от которого зависят другие листы
   */
  updateDependentFormulas(HF_sheetId: number, LS_sheetIndex: string) {
    const cellsForUpdating: any = [];

    const HF_currentSheetName = this.manager.getSheetName(HF_sheetId);
    const HF_allSheets = this.manager
      .getSheetNames()
      .filter(sheetName => sheetName !== HF_currentSheetName);

    HF_allSheets.forEach(sheetName => {
      const HF_currentId = this.manager.getSheetId(sheetName);

      if (HF_currentId !== undefined && HF_currentSheetName) {
        const sheetFormulas = this.manager.getSheetFormulas(HF_currentId);
        const currentFile = this.LS_sheets.find(
          shs => shs.index === LS_sheetIndex
        );

        const currentLSData = currentFile.data;

        sheetFormulas.forEach((rowItem, row) => {
          rowItem.forEach((updatedFormula, col) => {
            if (
              currentLSData &&
              updatedFormula &&
              updatedFormula.includes(HF_currentSheetName)
            ) {
              // const address: SimpleCellAddress = {
              //   col,
              //   row,
              //   sheet: HF_currentId,
              // };
              // const cellForMutate: ExportedCellChange = {
              //   address: {
              //     col,
              //     row,
              //     sheet: HF_currentId,
              //   },
              //   sheet: HF_currentId,
              //   col,
              //   row,
              //   value: updatedFormula,
              //   newValue: updatedFormula,
              // };
              // this.LS_mutateCelldata(cc);
              // const currentCell = currentLSData[row][col];
              // currentLSData[row][col] = {
              //   ...currentLSData[row][col],
              //   f: removeQuotesFromWords(updatedFormula, russianSheets),
              // };
              // cellsForUpdating.push({
              //   ...currentLSData[row][col],
              //   f: removeQuotesFromWords(updatedFormula, russianSheets),
              //   address: {
              //     r: row,
              //     c: col,
              //     i: currentSheetIndex,
              //   },
              // });
            }
          });
        });
      }
    });

    if (cellsForUpdating.length > 0) {
      server.saveParam("gv", 0, cellsForUpdating);
    }

    luckysheetrefreshgrid();
  }

  LS_mutateCelldata(HF_cells: ExportedChange[]) {
    const russianSheets = this.manager.getSheetNames().filter(containsRussian);
    const cellsCreated: any[] = [];
    const cellsUpdated: any[] = [];

    HF_cells.forEach(HF_cell => {
      if (HF_cell instanceof ExportedCellChange) {
        const { address: HF_address, newValue } = HF_cell;
        const { row: HF_row, col: HF_col } = HF_address;

        // TODO: Убрать пустое название
        const HF_sheetName = this.manager.getSheetName(HF_address.sheet) || "";
        const LS_sheetIndex = this.LS_getSheetIndexByName(HF_sheetName);

        const LS_currentSheet = this.LS_sheets.find(
          shs => shs.index === LS_sheetIndex
        );

        const LS_celldata: any[] = LS_currentSheet?.celldata || [];
        const LS_currentCell = LS_celldata.find(
          curCell => curCell.r === HF_row && curCell.c === HF_col
        );
        const LS_currentIndexCell = LS_celldata.findIndex(
          curCell => curCell.r === HF_row && curCell.c === HF_col
        );
        const { v: LS_currentValue } = LS_currentCell || {};
        const isUpdateOperation =
          LS_currentIndexCell !== -1 && LS_currentValue !== null;

        let LS_updatingCell = LS_currentValue ?? { fa: "General", t: "g" };
        const isCurrentSheet =
          Store.currentSheetIndex.toString() === LS_sheetIndex;
        const isExistFormula = this.manager.doesCellHaveFormula(HF_address);
        const HF_formula = this.manager.getCellFormula(HF_address);

        const isErrorValue = newValue instanceof DetailedCellError;

        LS_updatingCell = {
          ...LS_updatingCell,
          ...(isExistFormula &&
            HF_formula && {
              f: removeQuotesFromWords(HF_formula, russianSheets),
            }),
          ...(HF_cell.newValue !== null && { m: HF_cell.newValue.toString() }),
          v: isErrorValue ? newValue.value : newValue,
        };

        if (isCurrentSheet) {
          Store.flowdata[HF_row][HF_col] =
            HF_cell.newValue === null ? null : LS_updatingCell;
        }

        if (isUpdateOperation) {
          cellsUpdated.push({
            ...LS_updatingCell,
            address: {
              r: HF_row,
              c: HF_col,
              i: LS_sheetIndex,
            },
          });

          LS_celldata[LS_currentIndexCell].v = LS_updatingCell;
        } else {
          cellsCreated.push({
            cell: {
              ...LS_updatingCell,
              r: HF_row,
              c: HF_col,
            },
            index: LS_sheetIndex,
          });

          LS_celldata.push({
            r: HF_row,
            c: HF_col,
            v: LS_updatingCell,
          });
        }
      }
    });

    return { cellsCreated, cellsUpdated };
  }

  private LS_saveCells(LS_cellsCreated: any[], LS_cellsUpdated: any) {
    LS_cellsCreated.forEach(item => {
      server.saveParam("v", item.index, item.cell, {
        r: item.cell.r,
        c: item.cell.c,
      });
    });

    if (LS_cellsUpdated.length > 0) {
      server.saveParam("gv", undefined, LS_cellsUpdated);
    }
  }
}
